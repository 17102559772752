import React from "react";
import Header from "./Header";

import bg1 from "./images/bg1.jpg";

const ProfessionalDetailing = () => {
  return (
    <section className="one">
      <img src={bg1} alt="" />
      <div>
        <Header />
        <article>
          <h2>
            <span>St. Tammany's</span> #1 Professional Detailing Service
          </h2>
          <p>
            <span>Experience</span>
            <span>Top</span>
            <span>of</span>
            <span>the</span>
            <span>Line</span>
            <span>Detailing</span>
            <span>Services</span>
            <span>Delivered</span>
            <span>Directly</span>
            <span>to</span>
            <span>You</span>
          </p>
          <a href="tel:+15042315484" className="button">
            Give us a call
          </a>
        </article>
      </div>
    </section>
  );
};

export default ProfessionalDetailing;
